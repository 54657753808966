export default function App() {
  return (
    <div className="h-fit bg-gradient-to-b from-[#47a2ed] to-[#323384] overflow-hidden">
      <img loading="lazy" className="w-screen" src="assets/IMG_0604.PNG" />
      <div className="flex flex-col flex-start items-center justify-center mx-auto max-w-screen-xl gap-2">
        <div className="max-w-screen-xl w-full">
          <div className="flex flex-wrap items-stretch gap-3 md:gap-6 mx-auto w-full justify-center bg-gradient-to-b from-transparent to-50% from-50% to-[#ffb44a]">
            <a href="#avatar" className="bg-[url('../public/assets/pagebutton_avatar.jpg')] bg-cover bg-center bg-no-repeat lg:basis-64 basis-16 h-16 rounded-lg align-middle border-[#4c1a1e] border-4">
              <span className="text-3xl lg:ml-0 -ml-1 mr-1 leading-loose font-serif align-middle text-left pl-2.5 text-white text-stroke-sm">
                Avatar
              </span>
            </a>
            <a href="#video" className="bg-[url('../public/assets/pagebutton_video.jpg')] bg-cover bg-center bg-no-repeat lg:basis-64 basis-16 h-16 rounded-lg font-serif text-3xl border-[#4c1a1e] border-4">
              <span className="text-3xl lg:ml-0 -ml-1 mr-1 leading-loose font-serif align-middle text-left pl-2.5 text-white text-stroke-sm">
                Video
              </span>
            </a>
            <a href="#icon" className="bg-[url('../public/assets/pagebutton_iconbase.jpg')] bg-cover bg-center bg-no-repeat overflow-hidden min-w-20 lg:basis-64 basis-16 h-16 rounded-lg font-serif text-3xl border-[#4c1a1e] border-4">
              <span className="text-3xl lg:ml-0 -ml-1 leading-loose font-serif align-middle text-left pl-2.5 text-white text-stroke-sm">
                Icon Base
              </span>
            </a>
            <a href="#art" className="bg-[url('../public/assets/pagebutton_art.jpg')] bg-cover bg-center bg-no-repeat lg:basis-64 basis-16 h-16 rounded-lg font-serif text-3xl border-[#4c1a1e] border-4">
              <span className="text-3xl lg:ml-0 -ml-1 mr-1 leading-loose font-serif align-middle text-left pl-2.5 text-white text-stroke-sm">
                Art
              </span>
            </a>
          </div>
          <div className="bg-[#ffb44a] min-h-[17rem] overflow-visible">
            <div className="flex flex-row justify-between gap-5 flex-wrap lg:flex-nowrap items-center mr-3">
              <div className="shrink-0 grow-0 grid grid-cols-4 grid-rows-3 h-60 w-80 gap-5 mt-5 mx-5">
                <div className="col-span-full row-span-2 border-4 rounded-xl border-red-600 bg-red-600 -ml-10 -mr-5">
                  <div className="text-white font-serif mt-2 text-right">
                    <span className="text-7xl align-super">DEAL</span>
                    <span className="text-[9rem] leading-none align-top ml-3">
                      <span className="align-super text-5xl">$</span>4
                      <span className="text-4xl">.</span>
                    </span>
                    <span className="text-2xl [writing-mode:vertical-lr] mt-3">
                      00 USD
                    </span>
                  </div>
                </div>
                <div className="border-4 rounded-xl border-white bg-white overflow-hidden">
                  <img loading="lazy"
                    className="m-auto h-full w-full object-cover"
                    src="assets/IMG_0592.PNG"
                  />
                </div>
                <div className="border-4 rounded-xl border-white bg-white overflow-hidden">
                  <img loading="lazy"
                    className="m-auto h-full w-full object-cover"
                    src="assets/Patreon_FW_2023Jun2_RoundCrocMeme_Zasz_HiRes.png"
                  />
                </div>
                <div className="border-4 rounded-xl border-white bg-white overflow-hidden">
                  <img loading="lazy"
                    className="m-auto h-full w-full object-cover"
                    src="assets/purekoor-the-tycoon-smol-english-1.jpg"
                  />
                </div>
                <div className="border-4 rounded-xl border-white bg-white overflow-hidden">
                  <img loading="lazy"
                    className="m-auto h-full w-full object-cover"
                    src="assets/low.png"
                  />
                </div>
              </div>

              <div className="grow shrink-0 grid grid-cols-5 grid-rows-4 gap-3 mt-3">
                <div className="col-span-full row-span-1">
                  <div className="text-white font-serif text-center">
                    <span className="text-6xl align-middle text-stroke-sm">
                      INCLUDES THIS
                    </span>
                  </div>
                </div>
                <div className="row-span-3 col-span-2">
                  <a href="https://patreon.com/purekoor">
                    <div className="w-44 h-44 mx-auto rounded-xl border-red-600 border-4">
                      <img loading="lazy" className="object-fill" src="assets/base_3.PNG" />
                    </div>
                  </a>
                </div>
                <div className="row-span-3 col-span-3 mx-auto">
                  <span className="text-3xl text-center font-serif font-extrabold underline text-white text-stroke-sm">
                    On Patreon
                  </span>
                  <ul className="text-2xl font-regular font-serif text-white text-stroke-sm">
                    <li>- Tanuki Fella Avatar</li>
                    <li>- Icon Bases</li>
                    <li>- Name in Video Credits</li>
                    <li>- Artwork</li>
                    <li>- AND MORE!</li>
                  </ul>
                </div>
              </div>

              <div className="md:m-auto mx-auto mb-3 grow-0 shrink-0 rounded-2xl border-4 bg-indigo-950 border-indigo-950 ">
                <a
                  href="https://patreon.com/purekoor"
                  className="h-48 w-48 flex mb-"
                >
                  <img loading="lazy"
                    className="object-contain h-40 m-auto"
                    src="assets/patreon.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="icon" className="bg-[#ffb44a] w-full">
          <h1 className="text-slate-50 font-serif text-6xl text-center text-stroke-sm mt-2">
            {" "}
            ʕ ᵔᴥᵔʔ✨ TRANSFORM YOUR SONA
          </h1>
          <div className="flex gap-5 flex-wrap justify-center items-center md:flex-nowrap m-5">
            <div className="text-4xl grow-0 shrink inline text-white font-extrabold text-stroke-sm">
              <p className="">
                <span className="inline-block">
                  Color in these PSD files to
                </span>
                <span className="inline-block">
                  make yourself a new icon or 
                </span>
                <span className="inline-block">
                  maybe find yourself a new species!
                </span>
              </p>
              <br />
              <p className="font-black text-4xl underline"><a href="https://purekoor.gumroad.com/">Check out my Gumroad</a></p>
            </div>
            <div className="grow-0">
              <div className="grid grid-rows- grid-cols-4 gap-3 w-80 h-60">
                <div className="col-span-full m-auto">
                  <h2 className="w-full text-center font-serif text-5xl align-middle inline-block text-stroke-sm text-white h-10">
                    <a href="https://purekoor.gumroad.com/">NEW BASES</a>
                  </h2>
                </div>
                <a
                  href="https://purekoor.gumroad.com/"
                  className="border-4 rounded-xl border-white row-start-2 row-end-4 col-start-1 col-end-3 "
                >
                  <img loading="lazy"
                    className="h-full object-cover"
                    src="assets/base_1.png"
                  />
                </a>
                <a
                  href="https://purekoor.gumroad.com/"
                  className="border-4 rounded-xl border-white row-start-2 row-end-4 col-start-3 col-end-5"
                >
                  <img loading="lazy"
                    className="h-full object-cover"
                    src="assets/base_2.PNG"
                  />
                </a>
              </div>
            </div>
            <div className="grid grid-rows-3 grid-cols-3 gap-3 w-60 h-60 shrink-0">
              <div className="h-full w-full border-4 rounded-xl border-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/Color_Tf_Series_Part_2(1).png"
                />
              </div>
              <div className="h-full w-full border-4 rounded-xl border-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/Color_Tf_Series_Part_2 (1).png"
                />
              </div>
              <div className="h-full w-full border-4 rounded-xl border-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/TF_Gif_stop_Mar_10_2023_Color_Part_1(2) (1).png"
                />
              </div>
              <div className="h-full w-full border-4 rounded-xl border-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/TF_Gif_stop_Mar_10_2023_Color_Part_1(2).png"
                />
              </div>
              <div className="h-full w-full border-4 rounded-xl border-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/TF_Gif_stop_Mar_10_2023_Color_Part_1(3) (1).png"
                />
              </div>
              <div className="h-full w-full border-4 rounded-xl border-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/TF_Gif_stop_Mar_10_2023_Color_Part_1(3).png"
                />
              </div>
              <div className="h-full w-full border-4 rounded-xl border-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/TF_Gif_stop_Mar_10_2023_Color_Part_1.png"
                />
              </div>
              <div className="h-full w-full border-4 rounded-xl border-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/TF_Gif_stop_Mar_10_2023_Color_Part_1(1) (1).png"
                />
              </div>
              <div className="h-full w-full border-4 rounded-xl border-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/TF_Gif_stop_Mar_10_2023_Color_Part_1(1).png"
                />
              </div>
            </div>
          </div>
        </div>
        <div id="video" className="bg-[#ffb44a] max-w-screen-xl flex flex-row justify-between h-full items-center gap-5 flex-wrap lg:flex-nowrap w-full">
          <a
            href="https://www.youtube.com/watch?v=mvi4YNuRhiY"
            className="rounded-xl border-4 border-white m-3 overflow-hidden shrink-0  mx-auto"
          >
            <img loading="lazy" className="h-64 object-cover" src="assets/thumbnail.jpg" />
          </a>
          <div className="grid grid-cols-2 grid-rows-5 gap-1  mx-auto">
            <a
              href="https://www.youtube.com/@PureKoor"
              className="col-start-1 col-end-2 row-start-1 border-4 bg-red-600 rounded-xl "
            >
              <p className="text-2xl text-center font-bold text-white align-middle hover:underline">
                Subscribe!
              </p>
            </a>
            <a
              href="https://www.tiktok.com/@purekoor"
              className="col-start-2 col-end-3 row-start-1 border-4 bg-black rounded-xl"
            >
              <p className="text-2xl text-center font-bold text-white align-middle hover:underline">
                Follow!
              </p>
            </a>
            <div className="my-auto row-start-2 col-span-full ml-5 -mr-5">
              <p className="my-auto text-2xl font-regular font-serif text-white text-stroke-sm">
                $1.00 on Patreon for name in credits!
              </p>
            </div>
            <div className="row-start-3 row-span-3 col-span-full ml-10">
              <ul className="text-xl font-regular font-serif text-white text-stroke-sm">
                <li>- Art Tutorials</li>
                <li>- Furry Fandom Analysis</li>
                <li>- Video Game Discussions</li>
                <li>- Stuff I find interesting</li>
              </ul>
            </div>
          </div>
          <div className="grid grid-cols-2 grid-rows-1 gap-3 m-3 shrink-0 mx-auto">
            <a
              href="https://www.tiktok.com/@purekoor/video/7277822528410733867"
              className="border-4 border-white rounded overflow-hidden"
            >
              <img loading="lazy"
                className="object-scale-down h-60"
                src="assets/tiktok_example_1_lowres.jpg"
              />
            </a>
            <a
              href="https://www.tiktok.com/@purekoor/video/7306872891331743022"
              className="border-4 border-white rounded overflow-hidden"
            >
              <img loading="lazy"
                className="object-scale-down h-60"
                src="assets/tiktok_example_2_lowres.jpg"
              />
            </a>
          </div>
        </div>

        <div id="avatar" className="bg-[#ffb44a] w-full">
          <div className="flex flex-row justify-between gap-5 flex-wrap lg:flex-nowrap items-center">
            <a href="https://purekoor.gumroad.com/" className="mx-auto md:mx-3 md:mt-0 mt-3">
              <img loading="lazy" className="h-72" src="assets/boing.webp" />
            </a>

            <div className="grow shrink-0 grid grid-cols-5 grid-rows-3 h-full gap-3 mb-5 mt-2">
              <div className="col-span-full row-span-1 my-auto">
                <div className="text-white font-serif text-center">
                  <p className="text-6xl align-middle text-stroke-sm hover:underline">
                    <a href="https://purekoor.gumroad.com/">TANUKI FELLA</a>
                  </p>
                  <p className="text-3xl align-middle text-stroke-sm">
                    My First Ever Avatar
                  </p>
                </div>
              </div>
              {/* <div className="col-start-3 row-start-2 rounded-full h-24 w-24 border-4 mx-auto border-[#4c1a1e]"></div>
              <div className="col-start-1 row-start-2 rounded-full h-24 w-24 border-4 mx-auto border-[#4c1a1e]"></div>
              <div className="col-start-2 row-start-2 rounded-full h-24 w-24 border-4 mx-auto border-[#4c1a1e]"></div>
              <div className="col-start-1 row-start-3 rounded-full h-24 w-24 border-4 mx-auto border-[#4c1a1e]"></div>
              <div className="col-start-2 row-start-3 rounded-full h-24 w-24 border-4 mx-auto border-[#4c1a1e]"></div>
              <div className="col-start-3 row-start-3 rounded-full h-24 w-24 border-4 mx-auto border-[#4c1a1e]"></div> */}
              <div className="row-span-2 col-span-full h-32 my-auto">
                <p className="text-2xl text-center font-extrabold text-white text-stroke-sm">
                  Made for VRChat
                </p>
                <p className="text-2xl text-center font-extrabold text-white text-stroke-sm">
                  Quest Compatible
                </p>
                <p className="text-2xl text-center font-extrabold text-white text-stroke-sm underline">
                  <a href="https://furality.online/explore/dealers?id=304">
                    Get it for free at Furality Umbra Booth #304
                  </a>
                </p>
              </div>
            </div>

            <div className="grow shrink-0 grid grid-cols-1 grid-rows-3 h-full gap-3 mr-3 mb-3">
              <div className="my-auto">
                <p className="text-2xl text-center font-extrabold text-white text-stroke-sm">
                  Available on
                </p>
              </div>
              <a
                href="https://purekoor.gumroad.com/"
                className="rounded-2xl border-4 h-20 w-20 mx-auto border-[#4c1a1e] overflow-hidden"
              >
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/LogoGumroad.png"
                />
              </a>
              <a
                href="https://www.patreon.com/purekoor"
                className="rounded-2xl border-4 h-20 w-20 mx-auto border-[#4c1a1e] overflow-hidden"
              >
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/LogoPatreon.png"
                />
              </a>
            </div>
          </div>
        </div>

        <div id="art" className="bg-[url('../public/assets/Patreon_FW_2021Apr29_NierReplicant_TXless_HiRes.png')] max-w-screen-xl  w-full bg-cover bg-top bg-no-repeat">
          <div className="flex flex-row justify-between items-start gap-5 my-5 flex-wrap w-full">
            <div className="shrink-0 grow-0 grid grid-cols-2 grid-rows-2 h-80 w-80 gap-5 mx-5">
              <a href="https://bsky.app/profile/purekoor.art" className="border-4 rounded-xl border-white bg-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/LogoBluesky.png"
                />
              </a>
              <a href="https://www.youtube.com/@PureKoor/community" className="border-4 rounded-xl border-white bg-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/LogoYoutube.png"
                />
              </a>
              <a href="https://www.instagram.com/purekoor/" className="border-4 rounded-xl border-white bg-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/LogoInstagram.png"
                />
              </a>
              <a href="https://t.purekoor.com/" className="border-4 rounded-xl border-white bg-white overflow-hidden">
                <img loading="lazy"
                  className="m-auto h-full w-full object-cover"
                  src="assets/LogoTelegram.png"
                />
              </a>
            </div>
            <div className="grow-0 shrink-0 text-4xl text-white font-serif bg-black bg-opacity-25 inline">
              <p className="mx-3 my-1">Artist for over a decade</p>
              <p className="mx-3 my-1">Furry Illustrations</p>
              <p className="mx-3 my-1">View my Artworks</p>
            </div>
          </div>
        </div>

        <div className="max-w-screen-xl w-full">
          <div className="grid w-2/3 grid-rows-2 grid-cols-1 mx-auto gap-1">
            <div className="text-right m-auto mb-1 font-bold text-white">
            <a className="underline" href="/links">All Websites</a> • <a className="underline" href="mailto:contact@purekoor.com">contact@purekoor.com</a>
            </div>
            <div className="col-span-full mx-auto flex items-center gap-2 mb-4">
              <img loading="lazy" className="h-8 object-scale-down" src="assets/logo.png" />
              <span className="text-xl text-center font-bold text-white text-stroke-sm">
                PureKoor '24
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
